import { isMobile } from "../helper";
import ScrollMagic from "scrollmagic/scrollmagic/minified/ScrollMagic.min.js";

class SiteAnimations {
  constructor() {
    this.anchorMenu = document.getElementById("anchor-menu");
    this.animationController = new ScrollMagic.Controller();
    this.init();
  }

  init() {
    if (!isMobile() && this.anchorMenu) {
      this.animateAnchorSections();
    }
  }

  animateAnchorSections() {
    let header = document.querySelector(".site-header");
    let offset = -header.offsetHeight - 100;
    let anchorLinks = this.anchorMenu.querySelectorAll(".anchor-element");
    let anchorSections = document.querySelectorAll([...anchorLinks].map(link => `${link.getAttribute("href")}`).join(", "));

    anchorSections.forEach(section => {
      let scene = new ScrollMagic.Scene({ triggerElement: section, duration: section.offsetHeight, offset: offset, triggerHook: 0 });

      scene.addTo(this.animationController);
      scene.on("enter leave", e => {
        const anchorID = section.id;
        const currentAnchor = this.anchorMenu.querySelector(`.${anchorID}-anchor`);

        if (anchorID == anchorSections[0].id) {
          if (e.type == "enter") {
            this.anchorMenu.classList.add("show");
          } else {
            if (e.state == "BEFORE") {
              this.anchorMenu.classList.remove("show");
            }
          }
        }

        if (currentAnchor) {
          let activeAnchor = this.anchorMenu.querySelector(".anchor-element.active");

          if (activeAnchor) {
            activeAnchor.classList.remove("active");
          }

          if (e.type == "enter") {
            currentAnchor.classList.add("active");
          }
        }
      });
    });
  }
}

window.addEventListener("load", () => new SiteAnimations());
