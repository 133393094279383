//js

require("./js/slider");
require("./js/scroll");
require("./js/helper");
require("./js/navigation");
require("./js/animations/site-animations");
require("./js/maps");
require("./js/main");

//fonts
require("./fonts/fonts.css");

// css
import "choices.js/public/assets/styles/choices.min.css";
import "normalize.css";
require("./sass/style.scss");
