import { isMobile, isPhone, moveSearchBoxToMenu, tabsModule } from "./helper";
import { toggleMenu, addSticky, closeMenu } from "./navigation";
import SiteHeader from "./siteHeader";
import Choices from "choices.js";
import FloatLabels from "./forms/float-labels";
import quantityCounter from "./quantity";
import $ from "jquery";

window.addEventListener("load", () => {
  new SiteHeader();
  new FloatLabels();

  const popupCloseButton = document.querySelector(".popup-close-button");
  if (popupCloseButton) {
    popupCloseButton.addEventListener("click", e => {
      // e.preventDefault();
      const popupContainer = document.querySelector(".free-shipping-info-container");
      popupContainer.classList.add("hidden");
    });
  }

  toggleMenu();

  if (document.querySelector(".js-choice")) {
    new Choices(".js-choice", {
      searchEnabled: false,
      itemSelectText: "",
    });
  }

  // if (isPhone()) {
  //   moveSearchBoxToMenu();
  // }

  if (isMobile()) {
    closeMenu();
    addSticky();
  }

  const attributesOptions = document.querySelectorAll('.single-product input[name^="attribute"]');

  if (attributesOptions.length) {
    attributesOptions.forEach(option => {
      option.addEventListener("change", e => {
        const select = document.querySelector(`select[name="${e.target.name}"]`);

        if (select) {
          select.value = e.target.value;
          $(select).trigger("change");
        }
      });
    });
  }

  const productCatalogImageSwitchers = document.querySelectorAll('.products-catalog-page input[name^="attribute"]');

  if (productCatalogImageSwitchers.length) {
    productCatalogImageSwitchers.forEach(option => {
      option.addEventListener("change", e => {
        let imageSrc = option.getAttribute("data-image");
        let productTile = option.closest(".single-catalog-tile");
        let imageElem = productTile.querySelector(".image img");
        imageElem.removeAttribute("srcset");

        if (imageSrc) {
          imageElem.src = `${imageSrc}`;
        }
      });
    });
  }

  if (document.body.classList.contains("single-product")) {
    new quantityCounter();
  }

  let tabs = document.querySelectorAll(".tabs-container");

  if (tabs.length) {
    tabs.forEach(tab => tabsModule(tab));
  }

  var switcher = $(".taxonomy-switcher");

  switcher.on("change", function(e) {
    var select = $(this);
    var slug = select.val();

    if (slug) {
      location.href = slug;
    }
  });

  let workshopsList = document.querySelector(".workshops-list-container");

  if (workshopsList) {
    const workshopFormField = workshopsList.querySelector('input[name="your-workshop"]');
    workshopsList.addEventListener("click", e => {
      if (e.target.classList.contains("signup")) {
        e.preventDefault();
        let workshopTitle = e.target.getAttribute("data-workshop");

        workshopFormField.value = workshopTitle;
        workshopsList.classList.add("form-toggled");
      }

      if (e.target.classList.contains("form-overlay")) {
        workshopFormField.value = "";
        workshopsList.classList.remove("form-toggled");
      }
    });
  }
});

window.onresize = () => {
  if (isMobile()) {
    closeMenu();
    addSticky();
  }
};
