//https://www.npmjs.com/package/smooth-scroll

import SmoothScroll from "smooth-scroll";

const scroll = new SmoothScroll('a[href*="#"]', {
  ignore: "[data-vc-container], .signup, .popup-close-button",
  header: "#masthead",
  offset: -35,
});

const scrollDownButtons = document.querySelectorAll(".scroll-page-down");

if (scrollDownButtons.length) {
  scrollDownButtons.forEach(button => {
    button.addEventListener("click", () => {
      const parentElem = button.parentElement;

      if (parentElem) {
        let animateVal = parseInt(parentElem.clientHeight) + 35;

        scroll.animateScroll(animateVal);
      }
    });
  });
}
