class quantityCounter {
  constructor() {
    this.priceHolder = document.querySelector(".price-box .woocommerce-Price-amount");
    this.quantitySwitchers = document.querySelectorAll(".quantity-selector");
    this.quantityInput = document.querySelector('input[name="quantity"]');
    this.variants = document.querySelectorAll('input[name^="attribute"]');
    this.price = 0;

    if (this.quantityInput) {
      this.addEvents();
    }
  }

  addEvents() {
    this.quantitySwitchers.forEach(switcher => switcher.addEventListener("click", e => this.changeQuantity(e, switcher)));
    this.quantityInput.addEventListener("input", () => this.changePrice());
    setTimeout(() => this.changePrice(), 500);

    if (this.variants.length) {
      this.variants.forEach(variant => variant.addEventListener("change", e => this.changePrice()));
    }
  }

  changeQuantity(e, switcher) {
    let currentValue = parseInt(this.quantityInput.value);
    let action = switcher.getAttribute("data-action");

    if (action == "plus") {
      this.quantityInput.value++;
    } else {
      if (currentValue > parseInt(this.quantityInput.getAttribute("min"))) {
        this.quantityInput.value--;
      }
    }

    let event = new Event("input");
    this.quantityInput.dispatchEvent(event);
  }

  changePrice() {
    const variationPrice = document.querySelector(".woocommerce-variation-price");

    if (variationPrice) {
      let prices = variationPrice.querySelectorAll(".woocommerce-Price-amount");
      this.price = parseFloat(prices[prices.length - 1].firstChild.textContent.replace(",", "."));
    } else {
      let singlePrice = document.querySelector(".title-row .woocommerce-Price-amount");
      this.price = parseFloat(singlePrice.firstChild.textContent.replace(",", "."));
    }

    let priceTotal = Math.round(this.quantityInput.value * this.price * 100) / 100;
    this.priceHolder.firstChild.textContent = priceTotal.toFixed(2).replace(".", ",");
  }
}

export default quantityCounter;
